import { createSelector } from 'reselect'
import { SET_N_STEPS, SET_IMAGE_SIZE, stepSelector } from './reducerRotator'

import { group0 } from './group0'
import { group1 } from './group1'
import { group2 } from './group2'
import { group3 } from './group3'

export const SET_GROUP = 'SET_GROUP'

const initialState = {
  currentGroup: '',
  groups: [group2, group3]
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case SET_GROUP: {
      return {
        ...state,
        currentGroup: action.id
      }
    }
    default:
      return state
  }
}
export default changeState

export const setGroup = (groupId) => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: SET_GROUP,
    id: groupId,
    stepCount: getGroupStepCount(state)(groupId)
  })
  dispatch({
    type: SET_N_STEPS,
    nSteps: getGroupStepCount(state)(groupId)
  })
  dispatch({
    type: SET_IMAGE_SIZE,
    size: getGroupImageSize(state)(groupId)
  })
}

export const currentGroupSelector = (state) => state.groups.currentGroup
export const groupsSelector = (state) => state.groups.groups

export const getGroup = createSelector(
  [groupsSelector],
  (groups) => (groupId) => {
    return groups.find((g) => g.id === groupId)
  }
)

//Grupo actual
export const getCurrentGroup = createSelector(
  [getGroup, currentGroupSelector],
  (gGroup, currentGroup) => {
    return gGroup(currentGroup)
  }
)

//Numero pasos grupo
export const getGroupStepCount = createSelector(
  [getGroup],
  (gGroup) => (groupId) => {
    const group = gGroup(groupId)
    return group ? group.images.length : 0
  }
)

//Tamaño imagen grupo
export const getGroupImageSize = createSelector(
  [getGroup],
  (gGroup) => (groupId) => {
    const group = gGroup(groupId)
    return group ? group.imageSize : { width: 0, height: 0 }
  }
)

//Tamaño imagen grupo actual
export const getCurrentGroupImageSize = createSelector(
  [getGroupImageSize, currentGroupSelector],
  (gGroupImageSize, currentGroup) => {
    return gGroupImageSize(currentGroup)
  }
)

//Imagenes grupo actual
export const getCurrentGroupImages = createSelector(
  [getCurrentGroup],
  (currentGroup) => {
    return currentGroup ? currentGroup.images : []
  }
)

//Colores grupo actual
export const getCurrentGroupColors = createSelector(
  [getCurrentGroup],
  (currentGroup) => {
    return currentGroup ? currentGroup.colors : []
  }
)

//Imagen del paso y grupo actuales
export const getCurrentImage = createSelector(
  [getCurrentGroup, stepSelector],
  (currentGroup, step) => {
    if (currentGroup) {
      const len = currentGroup.images.length
      if (step >= 0 && step < len) {
        return currentGroup.images[step]
      }
    }
    return ''
  }
)

//Mascara del paso y grupo actuales
export const getCurrentMaskImage = createSelector(
  [getCurrentGroup, stepSelector],
  (currentGroup, step) => {
    if (currentGroup) {
      const len = currentGroup.maskImages.length
      if (step >= 0 && step < len) {
        return currentGroup.maskImages[step]
      }
    }
    return ''
  }
)

//Segmentos de todos los pasos del grupo actual
export const getCurrentGroupAllStepsSegments = createSelector(
  [getCurrentGroup],
  (currentGroup) => {
    return currentGroup ? currentGroup.segments : []
  }
)

//Segmentos del paso (step) del grupo actual
export const getCurrentGroupStepSegments = createSelector(
  [getCurrentGroupAllStepsSegments],
  (currentGroupSegments) => (step) => {
    return step >= 0 && step < currentGroupSegments.length
      ? currentGroupSegments[step]
      : []
  }
)

//Segmentos del grupo y paso actuales
export const getCurrentSegments = createSelector(
  [getCurrentGroupStepSegments, stepSelector],
  (gCurrentGroupStepSegments, step) => {
    return gCurrentGroupStepSegments(step)
  }
)

//Segmento del grupo y paso actuales
export const getCurrentSegment = createSelector(
  [getCurrentSegments],
  (segments) => (color) => {
    return segments.find((segment) => segment.color === color)
  }
)

//Pois del segmento en el grupo y paso actuales
export const getCurrentLocalPois = createSelector(
  [getCurrentSegment],
  (gCurrentSegment) => (color) => {
    const segment = gCurrentSegment(color)
    return segment ? segment.pois : []
  }
)

//Poi del segmento en el grupo y paso actuales
export const getCurrentLocalPoi = createSelector(
  [getCurrentLocalPois],
  (gCurrentLocalPois) => (color, id) => {
    const pois = gCurrentLocalPois(color)
    return pois.find((poi) => poi.id === id)
  }
)

//Centroid del segmento en el grupo y paso actuales
export const getCurrentLocalCentroid = createSelector(
  [getCurrentLocalPoi],
  (gCurrentLocalPoi) => (color) => {
    const poi = gCurrentLocalPoi(color, 'centroid')
    return poi ? poi.point : [0, 0]
  }
)
