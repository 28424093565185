import { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import {
  currentGroupSelector,
  getGroupStepCount
} from '../../../../modules/reducerGroups'

import {
  stepSelector,
  setStep,
  setBoxVisible,
  zoomAtCenter
} from '../../../../modules/reducerRotator'

import {
  generateStep,
  clearStep,
  readStep,
  readStepPois,
  getGeneratedAllSegmentsBoundingBox
} from '../../../../modules/SegmentGenerator/reducerSegmentGenerator'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid black',
    padding: '5px 10px',
    margin: '5px 0px',

    display: 'flex',
    flexFlow: 'column'
  },
  title: { paddingBottom: '10px' },
  stepsWrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start'
  },
  stepButton: { width: '25%', minWidth: '0px', padding: '0px' },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  button: { width: '100%', padding: 0 }
}))

const RCGSteps = () => {
  const classes = useStyles()
  const [steps, setSteps] = useState([])
  const currentGroup = useSelector(currentGroupSelector)
  const currentStep = useSelector(stepSelector)
  const gGroupStepCount = useSelector(getGroupStepCount)
  const gGeneratedAllSegmentsBoundingBox = useSelector(
    getGeneratedAllSegmentsBoundingBox
  )
  const dispatch = useDispatch()

  useEffect(() => {
    const count = gGroupStepCount(currentGroup)
    const st = []
    for (let i = 0; i < count; ++i) {
      st.push(i)
    }
    setSteps(st)
  }, [currentGroup, gGroupStepCount])

  const handleGenerateStep = useCallback(() => {
    dispatch(generateStep(currentStep))
  }, [dispatch, currentStep])

  const handleReadStep = useCallback(
    (e) => {
      dispatch(readStep(currentStep))
    },
    [dispatch, currentStep]
  )

  const handleReadStepPois = useCallback(
    (e) => {
      dispatch(readStepPois(currentStep))
    },
    [dispatch, currentStep]
  )

  const handleClearStep = useCallback(() => {
    dispatch(clearStep(currentStep))
  }, [dispatch, currentStep])

  const handleStepClick = useCallback(
    (step) => {
      dispatch(setStep(step))
      const box = gGeneratedAllSegmentsBoundingBox(step)
      if (box[2] - box[0] > 0 && box[3] - box[1] > 0) {
        dispatch(setBoxVisible(gGeneratedAllSegmentsBoundingBox(step)))
        dispatch(zoomAtCenter(0.9))
      }
    },
    [dispatch, gGeneratedAllSegmentsBoundingBox]
  )

  return currentGroup !== '' ? (
    <div className={classes.root}>
      <div className={classes.title}>Steps</div>
      <div className={classes.stepsWrapper}>
        {steps.map((step, index) => (
          <Button
            key={step}
            className={classes.stepButton}
            variant="contained"
            color={currentStep === step ? 'secondary' : 'default'}
            disableFocusRipple
            onClick={(event) => handleStepClick(step)}
          >
            {step}
          </Button>
        ))}
      </div>

      <div className={classes.buttonsWrapper}>
        <button className={classes.button} onClick={handleGenerateStep}>
          Generate
        </button>
        <button className={classes.button} onClick={handleReadStep}>
          Read
        </button>
        <button className={classes.button} onClick={handleReadStepPois}>
          Pois
        </button>
        <button className={classes.button} onClick={handleClearStep}>
          Clear
        </button>
      </div>
    </div>
  ) : null
}
export default RCGSteps
